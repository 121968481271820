<template>
  <div>
    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">Prova</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <a href="#/resultados">Resultados</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Prova
                </li>
              </ol>
            </nav>
          </div>

          <h2
            class="color-ter fs-24 mb-2"
            v-if="estatisticasProva == undefined"
            style="text-align: center; margin-top: 10%"
          >
            Estatistica não processada para esta prova
          </h2>

          <div class="page-content" v-show="estatisticasProva != undefined">
            <Navigator />

            <div class="page-info mb-4">
              <div class="row align-items-end">
                <div class="col-md-6">
                  <h2 class="color-ter fs-24 mb-2">{{ descProva }}</h2>
                  <p>
                    <strong
                      >{{ descDisciplina }} -
                      {{ descAno }}
                      {{ descTurma }}</strong
                    >
                    | {{ dateTimeInicioProva }}
                  </p>
                </div>

                <div class="col-md-6 text-right">
                </div>
              </div>
            </div>

            <div class="page-content__charts block__classic p-4 mb-4">
              <div class="row justify-content-center">
                <div class="col-md-4 col-xl-3">
                  <div class="block__pie">
                    <p class="title">
                      Desempenho
                      <span
                        class="icon-tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        ><i class="help"></i
                      ></span>
                    </p>
                    <div class="app-chart">
                      <pie-chart
                        :data="chartDataDesempenho"
                        :options="chartOptions"
                        ref="desempenhoChart"
                      ></pie-chart>
                    </div>

                    <ul class="list-unstyled text-center">
                      <li>
                        <i class="success"></i> Corretas:
                        <b>{{ percentageCorrect }}%</b>
                      </li>
                      <li>
                        <i class="error"></i> Erradas:
                        <b>{{ wrongPercentage }}%</b>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-4 col-xl-3"></div>
                <div class="col-md-4 col-xl-3">
                  <div class="block__pie">
                    <p class="title">
                      Finalização
                      <span
                        class="icon-tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        ><i class="help"></i
                      ></span>
                    </p>

                    <div class="block__pie--canvas">
                      <div class="app-chart">
                        <pie-chart
                          :data="chartDataFinalizados"
                          :options="chartOptions"
                          ref="finalizadosChart"
                        ></pie-chart>
                      </div>
                    </div>

                    <ul class="list-unstyled text-center">
                      <li>
                        <i class="success"></i>Finalizaram a prova:
                        <strong>{{ percentageDid }}%</strong>
                      </li>
                      <li>
                        <i class="error"></i>Não finalizaram a prova:
                        <strong>{{ percentageDidNot }}%</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-8">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input type="search" class="form-control" id="search" />
                      </div>
                    </div>
                  </form>
                </div>

                <!--<div class="col-md-4 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <a
                        href="#"
                        @click.prevent="goDetalheProvaGeral(prova.id)"
                        class="btn btn-classic"
                        >Prova da Turma</a
                      >
                    </li> 
                    <li class="list-inline-item">
                      <a href="#" class="btn btn-classic">Imprimir</a>
                    </li>
                  </ul>
                </div>-->
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="block__classic table table__classic table-striped table-borderless table-hover text-center mb-4"
              >
                <thead>
                  <tr>
                    <th scope="col">Aluno</th>
                    <th scope="col">Nota</th>
                    <th scope="col">Desempenho</th>
                    <th scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="aluno in this.alunos" :key="aluno.id">
                    <td class="text-left" v-html="aluno.nome"></td>
                    <td class="text-center"><strong>{{ aluno.finalizadaEm ? aluno.nota : '-' }}</strong></td>
                    <td class="text-center">
                      <div v-if="aluno.finalizadaEm">
                        <b-progress class="mt-2" :max="100" show-value>
                          <b-progress-bar
                            :value="aluno.corretas"
                            variant="success"
                          ></b-progress-bar>
                          <b-progress-bar
                            :value="aluno.parciais"
                            variant="warning"
                          ></b-progress-bar>
                          <b-progress-bar
                            :value="aluno.erradas"
                            variant="danger"
                          ></b-progress-bar>
                        </b-progress>
                      </div>
                      <div v-else>
                        -
                      </div>

                    </td>

                    <td>
                      <button v-if="aluno.finalizadaEm"
                        class="btn btn-sm btn-classic"
                        @click.prevent="showResult(estatisticasProva.prova.id, aluno.id)"
                      >
                        <i class="far fa-eye" />
                      </button>
                      <div v-else>-</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import moment from "moment";
import Navigator from "../../../components/resultados/ResultadosNavigator.vue";
import Swal from "sweetalert2";
import PieChart from "./PieChart.js";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    Navigator,
    PieChart,
  },
  data() {
    return {
      showLeftMenu: true,
      percentageCorrect: 0,
      wrongPercentage: 0,
      respostas: [],
      nota: 0,
      percentageDid: 0,
      percentageDidNot: 0,
      alunos: [],
      alunoNota: [],
      estatisticasProva: null,
      turma: null,
      chartOptions: {
        hoverBorderWidth: 20,
        renderOnMount: false,
      },
      chartDataDesempenho: null,
      chartDataFinalizados: {},
    };
  },
  computed: {
    descProva () {
      return this.estatisticasProva && this.estatisticasProva.prova ? this.estatisticasProva.prova.titulo : '';
    },
    descDisciplina () {
      return this.estatisticasProva && this.estatisticasProva.prova && this.estatisticasProva.prova.disciplina ? this.estatisticasProva.prova.disciplina.text : '';
    },
    descAno () {
      return this.estatisticasProva && this.estatisticasProva.prova && this.estatisticasProva.prova.turma && this.estatisticasProva.prova.turma.anoEnsino ? this.estatisticasProva.prova.turma.anoEnsino.titulo : '';
    },
    descTurma () {
      return this.estatisticasProva && this.estatisticasProva.prova && this.estatisticasProva.prova.turma ? this.estatisticasProva.prova.turma.titulo : '';
    },
    dateTimeInicioProva () {
      return this.estatisticasProva && this.estatisticasProva.prova && this.estatisticasProva.prova.dataHoraInicio ? this.formatDate(this.estatisticasProva.prova.dataHoraInicio) : '';
    }
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadProva();
  },
  methods: {
    showResult(idprova, idaluno) {
      this.$router.push(
        "/resultados-detalhe-prova-aluno/" + idprova + "/aluno/" + idaluno
      );
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY - hh:mm");
      } else {
        return "-";
      }
    },
    goDetalheProvaGeral(idprova) {
      this.$router.push("/resultados-detalhe-prova-geral/" + idprova);
    },
    loadAlunos() {
      this.$api
        .get("alunos-turma/" + this.estatisticasProva.prova.turma.id)
        .then((response) => {
          // this.alunos = response.data;
          response.data.forEach((data) => {
            this.loadRespostas(data, this.estatisticasProva.prova.id);
          });
          console.log(response);
          if (response.data.length == 0) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">Esta prova ainda não foi aplicada</span>',
              showConfirmButton: false,
              timer: 2500,
            });
            // this.$router.go(-1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadRespostas(aluno, prova) {
      this.$api
        .get("resultado-prova-aluno/" + prova + "/" + aluno.id)
        .then((response) => {
          this.respostas = response.data.respostas;
          console.log(this.respostas);
          this.respostas.map(function(entry) {
            entry.alternativas = JSON.parse(entry.alternativas);
          });
          var corretas = 0;
          var erradas = 0;
          var parciais = 0;
          this.respostas.forEach((element) => {
            if (element.isCorreta) {
              corretas++;
            } else if (element.notaAlcancada > 0 && element.notaAlcancada < element.notaMaxima) {
              parciais++;
            } else {
              erradas++;
            }
          });
          let c = corretas / this.estatisticasProva.prova.qtdQuestoes;
          let e = erradas / this.estatisticasProva.prova.qtdQuestoes;
          let p = parciais / this.estatisticasProva.prova.qtdQuestoes;
          aluno.nota = (response.data.notaAlcancada / response.data.notaMaxima * 10).toFixed(2);
          aluno.corretas = c * 100;
          aluno.erradas = e * 100;
          aluno.parciais = p * 100;
          aluno.finalizadaEm = response.data.finalizadaEm
          this.alunos.push(aluno);
          console.log(aluno);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    loadProva() {
      this.$api
        .get("estatistica-prova/" + this.$route.params.idprova)
        .then((response) => {
          this.estatisticasProva = response.data;
          let proporcaoCorretas = this.estatisticasProva.qtdQuestoesCorretas / this.estatisticasProva.qtdQuestoes;
          let proporcaoErradas = this.estatisticasProva.qtdQuestoesErradas / this.estatisticasProva.qtdQuestoes;

          this.percentageCorrect = parseFloat(proporcaoCorretas  * 100).toFixed(2);
          this.wrongPercentage = parseFloat(proporcaoErradas * 100).toFixed(2);

          this.chartDataDesempenho = {
            hoverBackgroundColor: "red",
            hoverBorderWidth: 10,
            labels: ["Corretas", "Erradas"],
            datasets: [
              {
                backgroundColor: ["#41B883", "#E46651"],
                data: [
                  this.estatisticasProva.qtdQuestoesCorretas,
                  this.estatisticasProva.qtdQuestoesErradas,
                ],
              },
            ],
          };
          let naoFinalizaram = this.estatisticasProva.qtdAlunoProva - this.estatisticasProva.qtdFinalizacao;

          this.percentageDid = this.estatisticasProva.qtdFinalizacao / this.estatisticasProva.qtdAlunoProva * 100;
          this.percentageDidNot = 100 - this.percentageDid;

          this.chartDataFinalizados = {
            hoverBackgroundColor: "red",
            hoverBorderWidth: 10,
            labels: ["Finalizaram a prova", "Não finalizaram a prova"],
            datasets: [
              {
                backgroundColor: ["#41B883", "#E46651"],
                data: [this.estatisticasProva.qtdFinalizacao, naoFinalizaram],
              },
            ],
          };
          this.$refs.desempenhoChart.renderCustom(this.chartDataDesempenho);
          this.$refs.finalizadosChart.renderCustom(this.chartDataFinalizados);

          this.loadAlunos();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.app-chart {
  width: 275px;
  height: 275px;
}
.progress{
  height: 10px;
}
.progress-bar{
  font-size: 10px;
}
</style>
